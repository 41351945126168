import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/layout'

class FAQPage extends React.Component {
  render() {
    const faqPage = this.props.data.contentfulFaqPage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <section className="top">
          <Helmet title={faqPage.title} />
          <h1 className="hero-title">FAQ</h1>
          <ul className="faq-list">
            {faqPage.faqs.map((faq) => {
              return (
                <li key={faq.id}>
                  {faq.title && (
                    <h2 id={faq.slug}>
                      <a
                        class="anchor"
                        aria-hidden="true"
                        href={`#${faq.slug}`}
                      >
                        <span class="fa-link">
                          <FontAwesomeIcon icon={faLink} />
                        </span>
                      </a>
                      {faq.title}
                    </h2>
                  )}
                  {faq.question && (
                    <div>
                      <h3 id={faq.slug}>
                        <a
                          class="anchor"
                          aria-hidden="true"
                          href={`#${faq.slug}`}
                        >
                          <span class="fa-link">
                            <FontAwesomeIcon icon={faLink} />
                          </span>
                        </a>
                        {faq.question}
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: faq.answer.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  )}
                </li>
              )
            })}
          </ul>
        </section>
      </Layout>
    )
  }
}

export default FAQPage

export const pageQuery = graphql`
  query FaqPageQuery {
    contentfulFaqPage(id: { eq: "86be106d-1549-5923-a520-7d16361101b5" }) {
      title
      faqs {
        ... on ContentfulFaq {
          answer {
            childMarkdownRemark {
              html
            }
          }
          id
          question
          slug
        }
        ... on ContentfulFaqSection {
          id
          slug
          title
        }
      }
    }
  }
`
